<template>
  <section class="invoice-preview-wrapper">

    <b-row
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          id="quotation"
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body  class="invoice-padding pb-0">

            <div  class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div class="">
                <div class="logo-wrapper">
                  <logo />
                  <h3 class="text-primary invoice-logo">
                    冰山數據
                  </h3>
                </div>
                <p class="card-text mb-25">
                  甲方：玉案（北京）软件有限公司
                </p>
                <p class="card-text mb-25">
                  乙方：{{ vendorCompany.name }}
                </p>
                <p class="card-text mb-25">
                  项目名称：{{ projectData.project_name }}
                </p>
                <p class="card-text mb-25">
                  项目编号：{{ projectData.project_id }}
                </p>
                <p class="card-text mb-25">
                  框架合同编号：{{ vendorContracts[0].contract_iceberg_id }}
                </p>
                
                

              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  结算单
                  <span class="invoice-number">{{  }}</span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    开具日期：
                  </p>
                  <p class="invoice-date">
                    {{ dateDefault }}
                  </p>
                </div>

                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    授权代表：
                  </p>
                  <p class="invoice-date">
                    {{ bmName }}
                  </p>
                </div>

                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    邮箱：
                  </p>
                  <p class="invoice-date">
                    {{ bmEmail }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->

          <h6 class="text-center">准确率标准</h6>
          <b-table-lite
            :bordered="true"
            :striped="true"
            responsive
            :items="rateData"
            :fields="['元素', '准确率（%）', '备注']"
          >
            <template #cell(item)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data }}
              </b-card-text>
            </template>
          </b-table-lite>
          <!-- Invoice Description: Table -->
          <h6 class="text-center">结算统计表</h6>
          <b-table
              :striped="true"
              :bordered="true"
              :items="priceData"
              :fields="fields"
            >
          <template #cell(total)="data">
            {{ (data.item.quantity * data.item.cost).toFixed(2) }}
          </template>
          </b-table>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <b-card-text v-if="otherDescription.length" class="mb-0">
                  <span class="font-weight-bold">其他说明:</span>
                  <span class="ml-75">{{ otherDescription }}({{ statementTypeDes }})</span>
                </b-card-text>
              </b-col>
              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      合计:
                    </p>
                    <p class="invoice-total-amount">
                      {{ summary }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      含税:
                    </p>
                    <p class="invoice-total-amount">
                      3%
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      币种:
                    </p>
                    <p class="invoice-total-amount">
                      ￥人民币元
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">注意: </span>
            <span>甲乙双方应遵守商业保密义务，除双方企业内部相关人员外，请勿向任何第三方泄露该文档，感谢理解！</span>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>

          <!-- Button: Send Invoice -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle.sidebar-send-invoice
            variant="primary"
            class="mb-75"
            block
            @click="saveStatement"
          >
            保存
        </b-button>

          <!-- Button: Print -->

        </b-card>
      </b-col>
    </b-row>

    <b-row class="not-print-area">
      <b-col cols="12" xl="9" md="8">
        <b-card title="编辑">
          <b-card-body>
            <b-row>
              <b-col>
                <b-form-group
                  label="开具日期"
                  label-for="statementDate"
                >
                <flat-pickr
                  v-model="dateDefault"
                  class="form-control"
                />
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group
                  label="结算类型"
                  label-for="statementDate"
                >
                <v-select
                  v-model="statementType"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="typeOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="vpm"
                />
                </b-form-group>
              </b-col>
              <b-col>
              </b-col>
            </b-row>

          <div class="mb-1">
            <label for="textarea-default">结算统计</label>
            <b-table
              :bordered="true"
              :items="priceData"
              :fields="fields"
            >
            <template #cell(quantity)="data">
              <b-card-text class="font-weight-bold mb-25">
                <b-form-input
                  no-wheel
                  style="border: 0px"
                  v-model="data.item.quantity"
                  type="number"
                  class="text-center"
                  />
              </b-card-text>
            </template>

            <template #cell(total)="data">
              {{ (data.item.quantity * data.item.cost).toFixed(2) }}
            </template>
            </b-table>
          </div>

          <div>
            <label for="textarea-default">其他说明</label>
            <b-form-textarea
              v-model="otherDescription"
              id="textarea-default"
              placeholder=""
              rows="3"
            />
          </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>

import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle,BFormGroup,BFormInput,BFormTextarea,BTable
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    Logo,
    flatPickr,
    BTable,
    vSelect
  },
  data(){
    return{
      bmName: JSON.parse(localStorage.getItem('userData')).fullName,
      bmEmail: JSON.parse(localStorage.getItem('userData')).email,
      dateDefault: null,
      otherDescription: '该项目已通过甲方验收，达到准确率标准，并已确认该结算金额。',
      statementType: "all",
      priceData:[],
      rateData:[],
      projectData:{},
      vendorCompany:{},
      vendorCosts:{
        newest_cost:{
          cost_table:[]
        },
        histories:[],
      },
      vendorContracts:[
        {"contract_iceberg_id":null}
      ],
      fields: [
        {
          key: "element",
          label: "元素单位",
        },
        {
          key: "cost",
          label: "单价（元）",
        },
        {
          key: "note",
          label: "备注",
        },
        {
          key:"quantity",
          label:"数量",
        },
        {
          key:"total",
          label:"小计（元）",
        }
      ],
      typeOptions: [
        { value: 'batch', label: '批次结算' },
        { value: 'all', label: '结项结算' },
      ],
    }
  },
  methods: { 
    saveStatement(){
      var sendPriceData = JSON.parse(JSON.stringify(this.priceData))
      sendPriceData.forEach((row) => {
        if(!row.quantity){
          this.$toast.error("请填写数量")
          return
        }
        row.total = (row.quantity * row.cost).toLocaleString()
      })
      var newStetement = {
        "vendorCompanyName": this.vendorCompany.name,
        "vendorCompanyId": this.$route.params.companyId,
        "projectName": this.projectData.project_name,
        "projectId": this.$route.params.id,
        "contractIcebergId": this.vendorContracts[0].contract_iceberg_id,
        "date": this.dateDefault,
        "priceData": sendPriceData,
        "summary":this.summary.toLocaleString(),
        "rateData": this.rateData,
        "otherDescription": this.otherDescription + this.statementTypeDes
      }
      this.$axios.post("/collab/pm/gen_vendor_statement",newStetement).then(resp =>{
        if(resp.data.status == "ok"){
          this.$router.push({name: 'collab-pm-view', params: {id: this.$route.params.id}})
        }
      })
    },
    getPriceData(){
      this.$axios.get('/collab/pm/get_price_tables/' + this.$route.params.id).then(res => {
        let rateTable = (res.data.data.tables.newest_table.rate_table)
        rateTable.forEach(row => {
          this.rateData.push({
            "元素": row.element,
            "准确率（%）": row.rate,
            "备注": row.note,
          })
        })
        ;
      })
    },
    getProjectData(){
      this.$axios.get('/collab/pm/get_project/' + this.$route.params.id).then(res => {
        this.projectData = res.data.data.project
      })
    },
    getUsers(){
      this.$axios.get('/manage/users/get_users').then(res => {
        this.users = res.data.data.users
        let loginUserId = JSON.parse(localStorage.getItem('userData')).userName
        this.users.forEach(user => {
          if(user.id == loginUserId){
            return true
          }
        })
      })
    },
    getVendorCompany(){
      this.$axios.get('/vendors/companies/get_company/' + this.$route.params.companyId).then(res => {
        this.vendorCompany = res.data.data.company
      })
    },
    getVendorContracts(){
      this.$axios.get('/vendors/companies/get_contracts/' + this.$route.params.companyId).then(res => {
        this.vendorContracts = res.data.data.contracts
      })
    },
    getVendorCosts(){
      this.$axios.get('/collab/pm/get_vendor_cost_table/' + this.$route.params.id + '/' + this.$route.params.companyId ).then(res => {
        var table = (res.data.data.costs.newest_cost.cost_table)
        table.forEach(row => {
          this.priceData.push({
            "element": row.element,
            "cost": row.nowCost,
            "note": row.note,
          })
        })
      })
    },

  },
  created(){
    this.getPriceData()
    this.getProjectData()
    this.getUsers()
    this.getVendorCompany()
    this.getVendorContracts()
    this.getVendorCosts()
    let date = new Date()
    this.dateDefault = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
  },
  computed:{
    statementTypeDes(){
      if(this.statementType == 'all'){
        return '项目已全部完结，无后续批次结算。'
      }else{
        return '该结算单为批次结算单，项目未全部完结，后续仍有批次结算。'
      }
    },
    summary(){
      let sum = 0
      this.priceData.forEach(row => {
        sum += row.cost * row.quantity
      })
      return sum.toFixed(2)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>

<style lang="scss">

@media print {
  @page { margin: 0; size: landscape;}
  html{
    height:auto
  }
  // Global Styles
  body {
    background-color: transparent !important;
    height: auto;  
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
